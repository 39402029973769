.navbar {
  background-color: #00c588;
  color: #fff;
  width: 100%;
}

.navbar-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.navbar-content li {
  padding-left: 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

.avatar {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
