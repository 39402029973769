.container {
  width: 90%;
  margin: 0 auto;
}

.row-container {
  display: flex;
  align-items: center;
}

.col-1 {
  width: 50%;
  background: #279ac84d;
}
.col-2 {
  width: 50%;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.content {
  min-height: 100vh;
  display: flex;
  position: relative;
}

.welcom-text {
  font-size: 34px;
  color: #000;
  text-align: center;
  font-weight: 500;
  margin-bottom: 3rem;
}

.row-container {
  display: flex;
  align-items: center;
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
}

.row-container .col {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-content {
  width: 55%;
}

.verify-btn {
  margin-top: 36px;
  padding: 8px 70px;
  border-radius: 55px;
  font-weight: 500;
  letter-spacing: 0.22px;
  font-size: 18px;
}

.bottom-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 30px 0 20px;
}

.bottom-content p {
  margin: 0;
  margin-right: 4px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
}

.bottom-content a {
  text-decoration: underline;
  color: #279ac8;
  text-decoration-color: #279ac8;
  font-weight: 500;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .col-1 img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .col-1 {
    display: none !important;
  }
  .col-2 {
    width: 100% !important;
  }
}
