.landing-wrapper {
  background-image: url("/public/assets/images/bg-main.jpg");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 82vh;
}
.landing-top-img {
  width: 743px;
  height: 370px;
  margin-top: -3.5rem;
}
.landing-top {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}
.landing-top-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.landing-top-btn {
  text-align: center;
  margin-top: -55px;
}
.btn-black {
  outline: 0;
  background-color: #303030;
  border: 0;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 35px;
  font-size: 18px;
}
.top-image {
  width: 250px;
  height: auto;
  object-fit: contain;
  margin-top: -1.2rem;
}
.top-right-content {
  width: 250px;
  text-align: center;
}
.top-right-content button {
  margin-top: -1.2rem;
  padding: 10px 43px;
}

/* work section  */
.landing-section-work {
  width: 66%;
  margin: 0 auto;
  padding: 30px 0 0;
}
.landing-section-work h4 {
  margin-bottom: 0;
  font-size: 42px;
  color: #000;
  font-weight: 300;
  border-bottom: 1px solid #1a3950;
  padding-bottom: 15px;
}
.work-card {
  width: 68%;
  margin: 0 auto;
}
.work-card-img {
  width: 383px;
  height: 276px;
  object-fit: contain;
}
.work-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.work-card-footer {
  width: 383px;
  min-height: 184px;
  background-color: #1a3950;
  padding-bottom: 10px;
}
.work-card-footer p {
  color: #fff;
  font-size: 20px;
}
.work-footer-padding {
  padding: 5px 38px;
}
.work-number {
  color: #fff;
  font-size: 32px;
  padding: 0 8px;
}
.work-margin {
  margin-bottom: 5rem;
}
/* benfit  */
.landing-section-benefit {
  background-image: url("/public/assets/images/benefit.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  height: 750px;
}
.benefit-card-img {
  width: 100%;
  height: 244px;
  object-fit: cover;
}
.benefit-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 50px;
}
.benfit-width {
  width: 68%;
  margin: 0 auto;
  padding: 45px 0 30px;
}
.landing-section-benefit h1 {
  font-weight: 400;
  text-align: center;
  font-size: 48px;
  margin-bottom: 3.5rem;
}
.benefit-card {
  background-color: #fff;
  padding: 30px 20px;
  width: 285px;
  margin: 0 auto;
  border-top-right-radius: 58px;
  border-bottom-left-radius: 58px;
  height: 100%;
}
.benefit-card h4 {
  padding-top: 1.2rem;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  font-size: 22px;
  color: #000;
  margin-bottom: 12px;
}
.benefit-card p {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #191919;
}
.benfit--sing-btn {
  outline: 0;
  border: 0;
  background-color: #c4e9eb;
  width: 185px;
  height: 60px;
  border-radius: 55px;
  font-weight: bold;
  color: #000;
  font-size: 20px;
  margin-top: 3rem;
}
/* analyze  */
.landing-analyze h1 {
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 2.5rem;
}
.landing-analyze-img {
  width: 565px;
  height: 767px;
  margin: 0 auto;
}
.landing-analyze-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-analyze-content {
  width: 70%;
  margin-left: auto;
}
.landing-analyze p {
  font-size: 20px;
  color: #000;
  margin-bottom: 2rem;
}
.landing-analyze button {
  border: 1px solid #000;
  outline: 0;
  width: 236px;
  height: 60px;
  background: #fff;
  font-size: 18px;
}
.landing-analyze {
  overflow-x: hidden;
}
.footer {
  background-color: #000;
  height: 65px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.footer h5 {
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}

/* media  */
@media (max-width: 1759px) {
  .benfit-width {
    width: 80%;
  }
}

@media (max-width: 1499px) {
  .benfit-width {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .landing-section-work {
    width: 75%;
  }
  .work-card {
    width: 80%;
  }
  .benefit-card {
    width: 100%;
    padding: 20px 20px;
  }
  .benefit-card-img {
    height: 185px;
  }
  .benefit-card h4 {
    font-size: 18px;
  }
  .benefit-card p {
    font-size: 15px;
  }
}

@media (max-width: 1150px) {
  .work-card {
    width: 100%;
  }
  .work-card-img {
    width: 100%;
  }
  .work-card-footer {
    width: 100%;
  }
  .landing-analyze-img {
    width: 85%;
  }
  .landing-analyze-content {
    width: 90%;
  }
}

@media (max-width: 994px) {
  .benefit-card {
    width: 70%;
  }
  .benefit-card-img {
    height: 239px;
  }
  .landing-section-benefit {
    height: 100%;
  }
}

@media (max-width: 830px) {
  .benefit-card {
    width: 80%;
  }
  .landing-top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

@media (max-width: 769px) {
  .benefit-card {
    width: 100%;
  }
  .landing-analyze-content {
    margin-bottom: 4rem;
    margin-top: 4rem;
    padding: 0 20px;
  }
  .landing-top-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 551px) {
  .landing-analyze-content {
    width: 100%;
    text-align: center;
  }
  .benefit-card {
    width: 90%;
  }
  .landing-analyze h1 {
    font-size: 32px;
  }
  .landing-analyze p {
    font-size: 15px;
  }
  .landing-top-img {
    width: 100%;
    height: auto;
  }
  .landing-top-btn {
    margin-top: -30px;
  }
  .top-image {
    margin-top: 0;
  }
  .landing-analyze-img {
    width: 90%;
    height: 500px;
  }
  .footer {
    margin-top: 8px;
  }
}

@media (max-width: 440px) {
  .landing-wrapper {
    background-position: center;
    height: 65vh;
  }
}
