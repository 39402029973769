.input-container {
  position: relative;
  width: 100%;
}

.input-container .input-label {
  display: block;
  font-size: 16px;
  padding-bottom: 4px;
  color: #000;
  font-weight: 400;
  letter-spacing: -0.35px;
}

.input {
  outline: 0;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 4px;
  color: #000;
  width: 100%;
}

.input-right-icon {
  position: absolute;
  right: 0;
  bottom: 4px;
  cursor: pointer;
}

.input-right-icon i {
  color: #797979;
}

.input::placeholder {
  color: #999999;
  font-family: "DM Sans", sans-serif;
}
