.dropdown-container {
  position: relative;
  cursor: pointer;
}

.dropdown-label {
  display: flex;
  align-items: center;
}

.dropdown-container .dropdown-content {
  position: absolute;
  top: 60px;
  left: -100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 8px 14px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.05);
}
.dropdown-container .dropdown-content > div {
  color: #000;
  font-size: 16px;
  cursor: pointer;
}
