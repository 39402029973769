.btn-custom {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}

.btn-custom:disabled {
  opacity: 0.65;
  cursor: default !important; 
}
