a {
  text-decoration: none;
}

li {
  list-style-type: none;
  margin: 0;
}

ul,
p {
  margin: 0;
  padding: 0;
}

.mt-5 {
  margin-top: 24px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 24px;
}

.my-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-xl {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mt-8 {
  margin-top: 3rem;
}

.lh-5 {
  line-height: 3;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}

.spin {
  animation: spin 1s infinite linear;
}

.cursor-pointer {
  cursor: pointer;
}

.text-grey {
  color: #9da09d;
}

.swal-text {
  color: #464646 !important;
  line-height: 24px !important;
}

.swal-button {
  background-color: #279ac8 !important;
}

.min-h-100{
  min-height: 100vh;
}

.version-content {
  position: absolute;
  bottom: 15px;
  right: 30px;
}

.version-content h5 {
  font-size: 16px;
  color: #00c588;
  font-weight: 500;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-wrapper .css-1wy0on6 {
  display: none;
}
