.detail-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.detail-wrapper input[type="number"] {
  -moz-appearance: textfield;
}

.relative {
  position: relative;
}

.container-main {
  width: 80%;
  margin: 0 auto;
}

.text-main {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3.5rem;
  color: #000;
}

.sub-text {
  font-size: 18px;
  text-align: center;
  margin-bottom: 3.5rem;
  color: #000;
}

.search-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.search-col {
  width: 50%;
}

.btn-col {
  padding-left: 15px;
}

.vh-70 {
  height: 70vh;
}

.form-label {
  margin-bottom: 4px !important;
  color: #000 !important;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: block;
}

.form-input {
  position: relative;
  outline: 0;
  border: 1px solid #a3a3a3;
  border-radius: 6px;
  padding: 6px 18px;
  color: #000;
  width: 95%;
}

.price {
  position: absolute;
  left: 18px;
  top: 53%;
  z-index: 99999;
  font-size: 14.5px;
}

.percent {
  position: absolute;
  /* left: 63px; */
  top: 25%;
  z-index: 99999;
  font-size: 14.5px;
}
​ .error {
  font-size: 11px;
  padding: 3px 0;
  color: red;
}

.lf-50 {
  left: 50px !important;
}

.form-input:focus {
  border-color: #1c64f2;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.card-detail {
  background-color: #e5f2ff !important;
  box-shadow: 1px 1px 7px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem !important;
  border: 0px solid #ddd !important;
}

.card-detail p {
  font-weight: 600;
  font-size: 16px;
  color: rgb(17 24 39/1);
  line-height: 2.4rem;
}
.card-detail p:last-child {
  color: #000;
  font-weight: 500;
}

.serach-input-area {
  padding: 16px 15px !important;
  font-size: 16px !important;
  background-color: #fbfbfb !important;
}

.pac-container {
  margin-top: 6px;
  font-family: "DM Sans", sans-serif;
  position: relative;
  width: calc(100% - 2px);
  margin-left: 1px;
  background-color: #fff !important;
  z-index: 4;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border: none !important;
  overflow-x: hidden !important;
  overflow-y: auto;
}

.pac-container::after {
  display: none;
}

.pac-item {
  display: inline-block;
  padding: 8px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top: 0;
  width: 100%;
  border-bottom: 1px solid #f1f1f4;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.pac-item:hover {
  background-color: #279ac8;
  color: #fff;
}

.pac-item:hover span {
  color: #fff !important;
}

.pac-item:hover .pac-icon {
  filter: brightness(0) invert(1);
}

.pac-item span:last-of-type {
  font-size: 16px !important;
  font-weight: 400;
  color: #000;
}

.pac-item:last-child {
  border-bottom: 0px !important;
}

.pac-item-query {
  font-size: 16px !important;
}

.example {
  margin: 5px 1px;
  font-size: 12px;
  color: #464646;
}

.btn-analysis {
  padding: 11px 22px;
}

.search-box{
  position: relative;
}

.search-icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #54545A;
  font-size: 19px;
}


@media (max-width: 767px) {
  .responsive {
    width: 50% !important;
  }
}

@media (max-width: 590px) {
  .search-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .search-col {
    width: 99%;
    margin-bottom: 1rem;
  }
  .btn-col {
    padding-left: 0;
    width: 100%;
  }
  .btn-col button {
    width: 100%;
  }
}
